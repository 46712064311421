import React from "react";
import { RotatingLines } from "react-loader-spinner";
import { ColorPalette } from "../utils/constants";

type Props = { message?: string };

export const CustomSpinner = ({ message }: Props) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "8em",
    }}
  >
    {message && <p>{message}</p>}
    <div>
      <RotatingLines strokeColor={ColorPalette.blue} />
    </div>
  </div>
);
